const THREE = require('three');
const POSTPROCESSING = require('postprocessing');

App({ el: 'background' });

function App(conf) {
    conf = {
        fov: 60,
        cameraZ: 1,
        lightIntensity: 30,
        ambientColor: 0x555555,
        directionalColor: 0xff8c19,
        light1Color: 0x0cc6600,
        light2Color: 0xd8547e,
        light3Color: 0x3677ac,
        ...conf
    }

    let renderer, camera, scene, loader
    let composer, textureEffect, effectPass, bloomEffect
    let width, height
    let cloudParticles = []
    
    init();

    function init() {
        renderer = new THREE.WebGLRenderer({ canvas: document.getElementById(conf.el) });
        camera = new THREE.PerspectiveCamera(conf.fov);
        camera.position.z = conf.cameraZ;

        camera.rotation.x = 1.16;
        camera.rotation.y = -0.12;
        camera.rotation.z = 0.27;

        updateSize();
        window.addEventListener("resize", updateSize, false);

        initScene();
    }

    function initScene() {
        scene = new THREE.Scene();
        scene.fog = new THREE.FogExp2(0x333333, 0.001);
        renderer.setClearColor(scene.fog.color);
        
        initLights();

        loader = new THREE.TextureLoader();

        loader.load("/static/resources/smoke.png", function(texture) {
            let cloudGeo = new THREE.PlaneBufferGeometry(149, 88);
            let cloudMaterial = new THREE.MeshLambertMaterial({ map: texture, transparent: true });

            for(let p = 0; p < 100; p++) {
                let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
                        
                cloud.position.set(Math.random() * 800 - 400, 500, Math.random() * 500 - 500);
                cloud.rotation.x = 1.16;
                cloud.rotation.y = -0.12;
                cloud.rotation.z = Math.random() * 2 * Math.PI;
                cloud.material.opacity = 0.55;
                cloudParticles.push(cloud);
                scene.add(cloud);
            }
        });
        
        bloomEffect = new POSTPROCESSING.BloomEffect({
            blendFunction: POSTPROCESSING.BlendFunction.COLOR_DODGE,
            kernelSize: POSTPROCESSING.KernelSize.SMALL,
            useLuminanceFilter: true,
            luminanceThreshold: 0.3,
            luminanceSmoothing: 0.75
        });
        composer = new POSTPROCESSING.EffectComposer(renderer);

        loader.load("/static/resources/orion_nebula.jpg", function(texture) {
            textureEffect = new POSTPROCESSING.TextureEffect({ blendFunction: POSTPROCESSING.BlendFunction.COLOR_DODGE, texture: texture });
            effectPass = new POSTPROCESSING.EffectPass(camera, bloomEffect, textureEffect);
            textureEffect.blendMode.opacity.value = 0.2;
            bloomEffect.blendMode.opacity.value = 1.5;
            effectPass.renderToScreen = true;
            composer.addPass(new POSTPROCESSING.RenderPass(scene, camera));
            composer.addPass(effectPass);
            render();
        });
    }

    function initLights() {
        const a = 100;
        const b = 200;
        const c = 300;
        const lightDistance = 300;
        const decay = 1.6;

        let ambient = new THREE.AmbientLight(conf.ambientColor);
        scene.add(ambient);

        let directionalLight = new THREE.DirectionalLight(conf.directionalColor);
        directionalLight.position.set(0, 0, 1);
        scene.add(directionalLight);

        let orangeLight = new THREE.PointLight(conf.light1Color, conf.lightIntensity, lightDistance, decay);
        orangeLight.position.set(b, c, a);
        scene.add(orangeLight);
        let redLight = new THREE.PointLight(conf.light2Color, conf.lightIntensity, lightDistance, decay);
        redLight.position.set(a, c, a);
        scene.add(redLight);
        let blueLight = new THREE.PointLight(conf.light3Color, conf.lightIntensity, lightDistance, decay);
        blueLight.position.set(c, c, b);
        scene.add(blueLight);
    }

    function updateSize() {
        width = window.innerWidth;
        height = document.querySelector('#header').clientHeight + document.querySelector('#main').scrollHeight;
        if(renderer && camera) {
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.near = 1;
            camera.far = 1000;
            camera.updateProjectionMatrix();
        }
    }

    function render() {
        cloudParticles.forEach(p => {
            p.rotation.z -= 0.001;
        });
        composer.render(0.1);
        requestAnimationFrame(render);
    }
}
